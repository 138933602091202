<template>
    <div class="form-box">
        <el-form :model="searchForm">
            <el-row :gutter="25">
                <el-col :span="5">
                    <el-form-item label="姓名：">
                        <el-input v-model="searchForm.name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="手机号：">
                        <el-input v-model="searchForm.phone" clearable maxlength="11" />
                    </el-form-item>
                </el-col>
                <el-col :span="3">
                    <el-button @click="search" type="primary">
                        <el-icon>
                            <Search />
                        </el-icon>
                        搜索
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table-box">
        <div class="table-head">
            <span class="table-title">业务员列表</span>
            <span class="table-btn">
                <el-button @click="addSalesman" type="primary">
                    <el-icon style="color:#fff">
                        <Plus />
                    </el-icon>
                    <span style="margin-left:15px">新增业务员</span>
                </el-button>
            </span>
        </div>
        <div class="table-main">
            <c-table ref="saleamanTableRef" :tableDataUrl="tableDataUrl" :isLoadData="true" :border="true">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column prop="name" label="姓名" />
                <el-table-column prop="phone" label="手机号" />
                <el-table-column label="创建时间">
                    <template #default="scope">
                        {{ timeFormat(scope.row.created_at) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link type="primary" @click="edit(scope.row)">编辑</el-link>
                    </template>
                </el-table-column>
            </c-table>
        </div>
    </div>
    <div class="addSalesman">
        <el-dialog v-model="isShowAddSaleaman" width="400px" :draggable="true" :destroy-on-close="true" @close="cancel">
            <template #header>
                {{ isEdit? '编辑': '新增' }}业务员
            </template>
            <el-form :model="salesmanForm" label-width="100px" :rules="rules">
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="salesmanForm.name" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="salesmanForm.phone" maxlength="11" clearable />
                        </el-form-item>
                    </el-col>
                </el-row><el-row>
                    <el-col :span="18">
                        <el-form-item label="登录密码" prop="password">
                            <el-input v-model="salesmanForm.password" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="cancel">取消</el-button>
                <el-button v-if="isEdit" @click="editSubmit" type="primary">修改</el-button>
                <el-button v-else @click="submit" type="primary">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, reactive } from 'vue'
import cTable from '@/components/CustomTable'
import { timeFormat } from '@/func/time'
import { phone } from '@/func/validatorFunc';
import { Plus, Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';

const { proxy } = getCurrentInstance() // 当前实例
const tableDataUrl = proxy.$api.salesman.list
const saleamanTableRef = ref(null)
const isShowAddSaleaman = ref(false)
const isEdit = ref(false)
const salesmanForm = reactive({
    name: '',
    phone: '',
    password: ''
})

const searchForm = reactive({
    name: '',
    phone: ''
})
const rules = reactive({
    name: [
        { required: true, message: '此项必填' }
    ],
    phone: [
        { required: true, validator: phone, trigger: 'change' }
    ],
    password: [
        { required: true, message: '此项必填', trigger: 'change' }
    ]
})
const addSalesman = () => {
    isShowAddSaleaman.value = !isShowAddSaleaman.value
}

const cancel = () => {
    isShowAddSaleaman.value = false
    isEdit.value = false
}

const edit = (item) => {
    isShowAddSaleaman.value = !isShowAddSaleaman.value
    isEdit.value = !isEdit.value
    salesmanForm.name = item.name
    salesmanForm.phone = item.phone
    salesmanForm.id = item.id
}

const submit = () => {
    proxy.$api.salesman.add(salesmanForm).then(r => {
        if (r.status == 200) {
            isShowAddSaleaman.value = !isShowAddSaleaman.value
            saleamanTableRef.value.refresh()
        } else {
            ElMessage({ message: r.error.message, type: "error" })
        }
    })
}

const editSubmit = () => {
    proxy.$api.salesman.edit(salesmanForm.id, salesmanForm).then(r => {
        if (r.status == 200) {
            isShowAddSaleaman.value = !isShowAddSaleaman.value
            saleamanTableRef.value.refresh()
        } else {
            ElMessage({ message: r.error.message, type: "error" })
        }
    })
}

const search = () => {
    saleamanTableRef.value.search(searchForm)
}
</script>
